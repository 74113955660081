<template>
    <div class="data-table-wrapper">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th v-for="column in config.columns"
                            :width="column.width"
                            @click="sortColumns(column.name)"
                            v-bind:class="{ 'sortable-column': column.sortable, 'text-center': column.dataAlign }">
                            {{ column.title }}
                            <i class="fal"
                            v-if="column.sortable"
                            v-bind:class="{ 'fa-sort-up': column.dir === 'asc', 'fa-sort-down': column.dir === 'desc', 'fa-sort' : column.dir === ''}">
                            </i>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="row in rows">
                    <tr v-html="parseRow(row)">
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row mb-4" v-if="config.showTotal" v-cloak>
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right table-total">
            <span>Total:</span> <span>{{ tableTotal | formatMoney }}</span>
            </div>
        </div>

        <div class="table-paging" v-bind:class="{ show: rows.length > 0 || Object.keys(rows).length > 0 }">
            <div class="table-showing">
                <select class="form-control" v-model="length" @change="changeLength()" v-if="total > 20" v-cloak>
                    <option value="20">20</option>
                    <option value="40">40</option>
                    <option value="60">60</option>
                    <option value="80">80</option>
                    <option value="80">100</option>
                </select>
                <span>
                Showing {{ showPageAmount }} of {{ total }}
                </span>
            </div>
            <div class="table-pagination">
                <ul class="pagination" v-if="lastPage > 1" v-cloak>
                    <li class="page-item" v-bind:class="{ active: index === page }" v-for="index in lastPage" :key="index">
                        <a href="#" class="page-link" @click.prevent="changeTablePage(index)">{{ index }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="data-table-loading" v-bind:class="{ 'show-loading': loading }"><span>Loading &hellip;</span></div>
    </div>
</template>

<script>
const _ = require('lodash');
const axios = require('axios');
const currency = require('currency.js');

const HTTP = axios.create(axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : app.csrfToken,
    'Authorization' : 'Bearer '+window.apiToken,
    'Content-Type': 'multipart/form-data'
});

    module.exports = {
        props: [ 'config' ],
        data: function() {
            return {
                loading: false,
                length: 20,
                page: 1,
                total: 0,
                lastPage: 0,
                searchQuery: '',
                rows: [],
                searchKeyupTime: 700
            };
        },
        computed: {
            showPageAmount(){
                let start = (this.page * this.length) - this.length;
                let count = this.page * this.length;
                if( count > this.total ){
                    count = this.total;
                }

                if( start === 0 ){
                    return 1;
                }

                return start +' - '+ count;
            },
            tableTotal(){
                let total = 0.00;
                this.rows.forEach( (v) => {
                    if( v.hasOwnProperty('total') ){
                        if(v.total){
                            total += parseFloat(v.total);
                        }
                    }
                });
                return total;
            }
        },
        filters: {
            formatMoney(value){
                if (!value) return '';
                return currency(value).format();
            }
        },
        methods: {
            getData(){
                this.showLoading();

                let url = this.config.fetchUrl+'?page='+this.page+'&order_by='+this.config.orderBy+'&sort_dir='+this.config.dir+'&length='+this.length+'&search_query='+this.searchQuery;

                let keys = Object.keys(this.config.extraParams);
                keys.forEach( v => {
                    url += '&'+v+'='+this.config.extraParams[v].value;
                });

                HTTP.get(url)
                .then(response => {
                    this.rows = response.data.data;
                    this.total = response.data.total;
                    this.lastPage = response.data.last_page;
                    this.hideLoading();
                })
                .catch(e => {
                    console.log('Data Table Error', e);
                    this.hideLoading();
                });
            },
            update(){
                this.getData();
            },
            sortColumns(column){
                this.config.orderBy = column;

                let keys = Object.keys( this.config.columns );
                this.config.columns.forEach( v =>  {
                    if( v.name === column ){
                        let dir = v.dir;
                        if( dir === 'asc' ){
                            v.dir = 'desc';
                            this.config.dir = 'desc';
                        }
                        if( dir === 'desc' ){
                            v.dir = 'asc';
                            this.config.dir = 'asc';
                        }
                        if( dir === '' ){
                            v.dir = 'asc';
                            this.config.dir = 'asc';
                        }
                    } else {
                        v.dir = '';
                    }

                });

                setTimeout( () => {
                    this.getData();
                }, 100 );
            },
            changeTablePage(index){
                this.page = index;
                this.getData();
            },
            changeLength(){
                this.page = 1;
                this.getData();
            },
            decodeHTML(html) {
                var txt = document.createElement('textarea');
                txt.innerHTML = html;
                return txt.value;
            },
            parseRow(row){
                let tableRow = '';

                this.config.columns.forEach( v  => {

                    let align = 'text-left';

                    if( v.hasOwnProperty('dataAlign') ){
                        switch( v.dataAlign ){
                            case 'center':
                                align = 'text-center';
                            break;
                            case 'right':
                                align = 'text-right';
                            break;
                        }
                    }

                    tableRow += '<td class="'+align+'">';

                    if( v.type !== 'actions' ){
                        if( v.hasOwnProperty('template') ){
                            tableRow += v.template(row);
                        } else {
                            tableRow += row[v.name];
                        }
                    }

                    if( v.type === 'actions' ){
                        if( v.hasOwnProperty('template') ){
                            tableRow += v.template(row);
                        } else {
                            tableRow += row[v.name];
                        }
                    }

                    tableRow += '</td>';

                });

                return tableRow;
            },
            showLoading(){
                setTimeout( () => {
                    this.loading = true;
                });
            },
            hideLoading(){
                setTimeout( () => {
                    this.loading = false;
                }, 1100);
            }
        },
        created(){
            this.update = window._.debounce(this.update, this.searchKeyupTime);
        },
        mounted(){
            this.getData();
        }
    };
</script>

<style scoped>
.data-table-wrapper{
    position: relative;
}
.data-table-loading{
    visibility: hidden;
    width: 200px;
    padding: 32px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    opacity: 0;
    border-radius: 4px;
    background-color: rgba(000,000,000, .85);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .7s ease;
    box-shadow: none;
    z-index: 999999;
}
.data-table-loading.show-loading{
    visibility: visible;
    box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07)
    ;
    opacity: 1;
    transform: translate(-50%, -50%);
}
</style>
