<template>
    <div class="cards-group" v-bind:class="{ 'three-col': config.three_col }">
        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The amount of revenue combined generated by cards sold for the time period selected"
            v-if="config.revenue"
            v-cloak
        >
            <h6 class="card-title-sm">Total Credit Card Revenue</h6>
            <p class="card-value mb-0" v-if="cardStats.total_credit_card_revenue" v-cloak>
                {{ cardStats.total_credit_card_revenue | formatMoney }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.total_credit_card_revenue_change"
                    v-cloak
                    >{{ cardStats.total_credit_card_revenue_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The number of cards sold for the time period selected"
            v-if="config.card_sales"
            v-cloak
        >
            <h6 class="card-title-sm">Number of Credit Card Sales</h6>
            <p class="card-value mb-0" v-if="cardStats.number_of_credit_card_sales" v-cloak>
                {{ cardStats.number_of_credit_card_sales | formatThousand }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.number_of_credit_card_sales_change"
                    v-cloak
                    >{{ cardStats.number_of_credit_card_sales_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The number of clicks from MileValue to the product page on CreditCards.com using your tracking links for the time period selected"
            v-if="config.referrals"
            v-cloak
        >
            <h6 class="card-title-sm">Total Referrals</h6>
            <p class="card-value mb-0" v-if="cardStats.total_referrals" v-cloak>
                {{ cardStats.total_referrals | formatThousand }}
                <span class="card-stat-change" v-if="cardStats.total_referrals_change" v-cloak>{{
                    cardStats.total_referrals_change
                }}</span>
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="This is the number of clicks from the product page on CreditCards.com to the application using your tracking links for the time period selected"
            v-if="config.clicks"
            v-cloak
        >
            <h6 class="card-title-sm">Total Card Clicks</h6>
            <p class="card-value mb-0" v-if="cardStats.total_card_click" v-cloak>
                {{ cardStats.total_card_click | formatThousand }}
                <span class="card-stat-change" v-if="cardStats.total_card_click_change" v-cloak>{{
                    cardStats.total_card_click_change
                }}</span>
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The amount of revenue combined generated by cards sold divided by the number of Credit Card Clicks for the time period selected"
            v-if="config.eaernings"
            v-cloak
        >
            <h6 class="card-title-sm">Earnings Per Click</h6>
            <p class="card-value mb-0" v-if="cardStats.earnings_per_click" v-cloak>
                {{ cardStats.earnings_per_click | formatMoney }}
                <span class="card-stat-change" v-if="cardStats.earnings_per_click_change" v-cloak>{{
                    cardStats.earnings_per_click_change
                }}</span>
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="The amount of Credit Card Clicks divided by the number of Referrals for the time period selected"
            v-if="config.click_rate"
            v-cloak
        >
            <h6 class="card-title-sm">Click Through Rate</h6>
            <p class="card-value mb-0" v-if="cardStats.click_thru_rate" v-cloak>
                {{ cardStats.click_thru_rate.toFixed(2) }}%
                <span class="card-stat-change" v-if="cardStats.click_thru_rate_change" v-cloak>{{
                    cardStats.click_thru_rate_change
                }}</span>
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="New Anonymous IDs"
            v-if="config.new_anon_ids"
            v-cloak
        >
            <h6 class="card-title-sm">New Anonymous IDs</h6>
            <p class="card-value mb-0" v-if="cardStats.new_anon_ids" v-cloak>
                {{ cardStats.new_anon_ids }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.total_new_anon_ids_percent_change"
                    v-cloak
                    >{{ cardStats.total_new_anon_ids_percent_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>

        <div
            class="dashboard-card card-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="AVG Anonymous IDs"
            v-if="config.avg_anon_ids"
            v-cloak
        >
            <h6 class="card-title-sm">AVG Anonymous IDs</h6>
            <p class="card-value mb-0" v-if="cardStats.avg_anon_ids" v-cloak>
                {{ cardStats.avg_anon_ids }}
                <span
                    class="card-stat-change"
                    v-if="cardStats.avg_anon_ids_percent_change"
                    v-cloak
                    >{{ cardStats.avg_anon_ids_percent_change }}</span
                >
            </p>
            <div class="card-loader-sm" v-if="cardSmLoading" v-cloak>
                <i class="far fa-spinner-third fa-spin"></i>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
let currency = require('currency.js');

const HTTP = axios.create(
    (axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': app.csrfToken,
        Authorization: 'Bearer ' + window.apiToken,
        'Content-Type': 'multipart/form-data',
    })
);

module.exports = {
    props: ['startDate', 'endDate', 'config'],
    data: function () {
        return {
            cardSmLoading: true,
            cardStats: {
                total_credit_card_revenue: 0,
                number_of_credit_card_sales: 0,
                mv_direct_sales: 0,
                affiliate_commission: 0.0,
                mv_commission: 0.0,
                total_profit: 0.0,
                total_referrals: '00,000',
                total_card_click: '00,000',
                earnings_per_click: 0.0,
                click_thru_rate: 0.0,
                new_anon_ids: 0,
            },
        };
    },
    filters: {
        formatMoney(value) {
            if (!value) return '';
            return currency(value).format();
        },
        formatThousand(value) {
            if (!value) return '';
            return value.toLocaleString();
        },
    },
    methods: {
        getData() {
            this.cardSmLoading = true;
            HTTP.get('/api/cardstats?start_date=' + this.startDate + '&end_date=' + this.endDate)
                .then((response) => {
                    this.cardStats = response.data;
                    this.cardSmLoading = false;
                })
                .catch((e) => {
                    this.cardSmLoading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped></style>
